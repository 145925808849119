@font-face {
  font-family: "OpenSans";
  src: url("./static/OpenSans-Regular.ttf") format("woff2"),
    url("./static/OpenSans-Regular.ttf") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "OpenSans";
  src: url("./static/OpenSans-SemiBold.ttf") format("woff2"),
    url("./static/OpenSans-SemiBold.ttf") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "OpenSans";
  src: url("./static/OpenSans-ExtraBold.ttf") format("woff2"),
    url("./static/OpenSans-ExtraBold.ttf") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "LuckiestGuy";
  src: url("./static/LuckiestGuy-Regular.ttf") format("woff2"),
    url("./static/LuckiestGuy-Regular.ttf") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
